import lodash from "lodash";
import axios from "axios";

let localizationDictionary = {};
/*const updatedData = await axios.post(`/preferences/${ options.key }`, options.value);*/

export async function loadDefaultLocalizationDictionary() {
  try {
    const response = await axios.get("/preferences/dashboard-locale");
    await loadLocalizationDictionary(response.data.locale);
  } catch (error) {
    console.error("Can't load locale.", error);
  }
}

export async function loadLocalizationDictionary(locale) {
  try {
    const response = await axios.get(`/preferences/dictionary/${ locale }`);
    localizationDictionary = response.data;
  } catch (error) {
    localizationDictionary = {};
    console.error("Can't load locale.", error);
  }
}

export function localize(str, replace) {
  if (localizationDictionary[str]) {
    str = localizationDictionary[str];
  }

  if (lodash.isObjectLike(replace)) {
    Object.keys(replace).forEach(key => {
      str = str.replace(new RegExp(`\\[${ key }\\]`, "ig"), replace[key]);
    });
  }
  return str;
}

export default localize;
