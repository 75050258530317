import Vue from "vue";
import asyncTimeout from "../lib/async-timeout.js";
import checkImageStatus from "../lib/check-image-status.js";

Vue.directive("onready", {
  inserted: async function (node, binding) {
    if (node.tagName !== "IMG") {
      return console.warn("The directive \"onready\" might be applied only for HTML image element.");
    }

    for (var i = 0; i < 20; i ++) {
      await asyncTimeout(50);
      /* The image is loading and already initialized. */
      if (checkImageStatus(node) > 0) {
        break;
      }
    }

    try {
      binding.value();
    } catch (error) {
      console.error("Can't call callback of \"onready\" directive:", error);
    }
  }
});
